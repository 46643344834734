  <template>
    <main class="home">
   
      <!-- HEADER TOP MENU BAR -->
        <Header></Header>
      <!-- <Header :currentLanguage="currentLanguage"  :navbar="bannerData.navs"></Header> -->
      <!-- Rest of the HOME PAGE STARTS -->
        <div class="LandingPage">
          <!-- SECTION 1 -->  
          <section  v-for="(data,index) in homePageContentData" :key="index" :class="data.templateType.slug+'BackImage'" > 
            <b-container>
              <Sections :sectionData="data" :sectionType="data.templateType.slug" />
            </b-container>
          </section>
          </div>
          
      <!-- footer section -->
            <section class="footer">
              <footer>
                <cookie-law theme="dark-lime"></cookie-law>
              </footer>
              <Footer/>
            </section>
    </main>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sections from "../components/Section";
const _ = require('lodash');
import { mapGetters } from "vuex";

export default {
  name: "GetInvolved",
  components: {
    Sections,
    Header,
    Footer,
  },
  computed: {
    ...mapGetters(["language", "content"]),
    homePageContentData() {
        var route=this.$route.path.split('/');
        var newArr=[]; 
        if (this.content && this.content.pageContent) {
            newArr= this.content.pageContent.filter((el)=> {
              if(el.linkedPage.length>0 && el._fl_meta_.status!='review'){
                  return el.linkedPage[0].pageSlug == route[1] ;
              }
                
            });
        return _.orderBy(newArr, 'orderNumber', 'asc');
        } else return {};
    },
   
  
   
    currentLanguage() {
      if (this.language) {
        return this.language;
      } else return "english";
    },
  },
  
  async mounted() {
    this.scrollToTop()
    await this.$gtag.query('event', 'screen_view', {
        app_name: 'MyApp',
        screen_name: 'Get Involved page',
      })
  },

 methods: { 

      scrollToTop() {
          window.scrollTo({top: 0,left: 0, behavior: 'smooth'});
 
      }
  },
};
</script>
